/* PrivacyPolicy.css */
.privacy-policy-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* height: 100vh; */
    padding: 20px;
    background-color: #f4f4f9;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
  
  .editor-container {
    /* flex-grow: 1; */
    width: 100%;
    max-width: 1200px; /* Optional: To limit the editor width */
    margin-bottom: 20px;
  }
  
  .editor {
    height: 50vh; /* Takes up most of the vertical space */
  }
  
  button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .button-con {
    margin-top: 40px;
  }
  .success-message {
    color: green;
    margin-top: 10px;
    font-size: 16px;
  }
  