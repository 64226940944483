.button_background0 {
  background: pink !important;
  border: none !important;
  padding: 3px 20px;
  border-radius: 10px;
  color: black !important;
  font-weight: 600 !important;
}

.button_background0 :hover {
  background: none !important;

}

.button_background1 {
  background: pink !important;
  border: none !important;
  padding: 3px 20px;
  margin-right: 20px !important;
  border-radius: 10px;
  color: black !important;
  height: 45px;
  font-weight: 600;
}

.button_background1 :hover {
  background: none !important;

}

.inputIcon {
  position: relative;
}

.IconArrow {
  position: absolute;
  bottom: 5px;
  left: 90%;
  font-size: 15px;
}

.IconAdd {
  position: absolute;
  top: 45px;
  right: 20px;
  color: rgb(248, 57, 88);
}

.IconAdd1 {
  position: absolute;
  top: 13px;
  right: 20px;
  color: rgb(248, 57, 88);
}

.ImageArrow {
  color: rgb(248, 57, 88);
  /* background-color: #ffa5c0; */
}

.headingback {
  color: rgb(248, 57, 88);
  background-color: #E033681A;
  padding: 5px 12px;
  border-radius: 16px;
}

.labelImge {
  color: rgb(248, 57, 88);
  font-size: 12px;
  font-weight: 300;
}

.iconimage {
  color: rgb(248, 57, 88);
}

.DeleteButton {
  border-radius: 10px;
  background-color: rgba(224, 51, 104, 0.15);
  border: none;
  font-size: 18px;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlighted-delete {
  background-color: #ffa5c0;
  color: #fdf5f7;
  border: 2px solid rgb(242, 156, 156);
}

.Viewbutton {
  background-color: #E03368;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 300;
  padding: 6px, 10px, 6px, 8px;
  color: white;
}

.imagehaeding {
  color: rgb(248, 57, 88);
}

.EditButton {
  background-color: #E03368;
  border: none;
  border-radius: 10px;
  color: white;
}

.Intrestname {
  /* background-color: #FAE4EB; */
  color: hsl(343, 81%, 58%);

  border-radius: 16px;
  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  /* gap: 8px; */
  padding: 4px;
}

.ModalDlt1 {
  color: #211F1F;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.224px;
  margin-bottom: 22px;
}

.ModalDlt {
  color: #211F1F;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.224px;
  margin-bottom: 22px;
}

.DelteButton {
  width: 158px;
  height: 52px;
  background-color: #FAE4EB;
  padding: 16px 32px;
  border-radius: 10px;
  color: #E03368;
  text-align: center;
  cursor: pointer;
}
.DelteButton:hover{
  box-shadow:3px 3px 7px 3px #888888;
}

.Allbutton {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}

.CencelButton {
  width:158px;
  height: 52px;
  background: linear-gradient(to right, #FF84A7, #E03368);
  padding:16px 32px;
  border-radius: 10px;
  color:white;
  text-align: center;
  cursor: pointer;
}
.CencelButton:hover{
  background: linear-gradient(to right, #FF84A7, #E03368);
  box-shadow: -3px 3px 7px 3px #888888;
}

.ImgaeSize {
  width: 74px;
  height: 62px;
}

.ImageIcon {
  width: 60px;
  height: 60px;
}

.ImgaeSize1 {
  width: 100%;
  height: 130px;
}

.ImgesModal {
  width: 100%;
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto auto;
  gap: 10px;
}

.Modal-content {
  overflow: hidden;
}

.Crousel {
  width: 100%;
  height: 200px;
  /* border-radius: 10px 10px 0px 0px; */
}

.themeName {
  font-size: 20px;
  font-weight: 600;
  color: #1B1919;

}

.IconSize {
  width: 28px;
  height: 28px;

}

.IntrestTab {
  background-color: #f8d7e1;
  color: #FF84A7;
  font-size: 16px;
  font-weight: 300;
  border-radius: 16px;
  padding: 4px 9px !important;
}

.ThemeName {
  font-size: 20px;
  font-weight: 400;
  color: #211F1F;
}

.Buttoncarusel{
width: 30px !important;
}
.Buttoncarusel:hover {
  background: transparent;
}

.InterestSize {
  width: 30% !important;
}


.parentIntrest {
  position: relative;
}

.NameIntrest {
  position: absolute;
  bottom: 1%;
  justify-content: center;
  /* padding: 5px 30px; */
  align-items: center;
  text-align: center;
  color: white;
  font-size: 20px;
  right: 50%;
}

.InterestSize1 {
  width: 200px !important;
}

.DltCustom {
  padding: 0 15px;
  overflow: hidden;
  color: #E03368;
}

.headingback1 {
  /* color: rgb(248, 57, 88); */
  /* background-color: #E033681A; */
  color: black;
  /* padding: 5px 15px; */
  /* border-radius: 16px; */
}

.DltCustom :hover {
  background: #f8d7e1;
  ;
}

.Backgroundimges {
  position: absolute;
  /* bottom: 99%; */
  top: 0%;
  left: 4%;
}

.Backgroundimges1 {
  position: absolute;
  bottom: 90%;
  left: 90%;
}

.themeName1 {
  color: rgb(0, 0, 0);
  font-size: 20px;
  font-weight: 600;
}