input[type="file"]::-webkit-file-upload-button {
    display: none !important;
}

input[type="file"]::file-selector-button {
    display: none !important;
}

.agerestrict,
.categoryrow {
    margin-top: 20px !important;
}

.cancelButton {
    border: none;
    background-color: transparent;
    margin: auto 30px;
}

/* The switch - the box around the slider */
.switch {
    font-size: 17px;
    position: relative;
    display: inline-block;
    width: 3.5em;
    height: 2em;
    transform: scale(0.7);
    margin-right: 10px;
    margin-left: -10px;
}


/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #B0B0B0;
    border: 1px solid #B0B0B0;
    transition: .4s;
    border-radius: 32px;
    outline: none;
}

.slider:before {
    position: absolute;
    content: "";
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    outline: 2px solid #B0B0B0;
    left: -1px;
    bottom: -1px;
    background-color: #fff;
    transition: transform .25s ease-in-out 0s;
}

.channelLogo {
    max-height: 35px !important;
}

.slider-icon {
    opacity: 0;
    height: 12px;
    width: 12px;
    stroke-width: 8;
    position: absolute;
    z-index: 999;
    stroke: var(--primaryColor);
    right: 60%;
    top: 30%;
    transition: right ease-in-out .3s, opacity ease-in-out .15s;
}


.form-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #344357;
    margin-bottom: 0.5rem;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    margin: 0;
    overflow: hidden;
    opacity: 0;
}

.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    overflow: hidden;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

.custom-file-input:lang(en)~.custom-file-label::after {
    content: "Browse";
}

.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + .75rem);
    padding: .375rem .75rem;
    line-height: 1.5;
    color: #495057;
    content: "Browse";
    background-color: #e9ecef;
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0;
}